import { Link } from 'gatsby'
import React from 'react'
import { routes } from '../../../../lib/routes'
import { Button } from '../../../Shared/Button/Button'

export interface MakeAnAppointmentHeaderProps {
	className?: string
}

export const MakeAnAppointmentHeader = (props: MakeAnAppointmentHeaderProps) => {
	return (
		<div className="max-w-lg">
			<h2 className=" relative text-white">Dohodnite si termín ešte dnes</h2>
			<p className="text-white mt-4 ">
				Chceli by ste nás navštíviť? Dohodnite si presný dátum a čas a vyhnite sa čakaniu v čakárni.
			</p>
			<div className="mt-6 relative z-50 flex  sm:space-x-4 flex-col sm:flex-row space-y-4 sm:space-y-0">
				<Link to={routes.contact.to}>
					<Button>Kontakt</Button>
				</Link>

				<Link to={`${routes.about.to}#ordinacne-hodiny`}>
					<Button className="bg-secondary border-secondary hover:text-secondary hover:border-secondary ">
						Ordinačné hodiny
					</Button>
				</Link>
			</div>
		</div>
	)
}
