import BackgroundImage from 'gatsby-background-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import { getAllServices } from '../../../Services/hooks/getAllServices'
import { overlay } from './services.module.scss'
import { convertToBgImage } from 'gbimage-bridge'
import { Link } from 'gatsby'
import { createServiceSlug } from '../../../Services/hooks/createServiceSlug'

export interface ServicesHomeProps {
	className?: string
}

const getBgImageFromService = (service: any) => {
	const image = getImage(service.image)
	const bgImage = convertToBgImage(image)
	return bgImage
}

export const ServicesHome = (props: ServicesHomeProps) => {
	const services = getAllServices()

	return (
		<div>
			<div className="flex  md:justify-start mb-8">
				<h2 className="md:text-left text-center">Naše služby</h2>
			</div>

			<div className={`grid md:grid-cols-3 grid-cols-1 gap-6 md:grid-rows-1 grid-rows-3 `}>
				{services.map((service, index) => (
					<Link
						to={`/sluzby/#${createServiceSlug(service.name)}`}
						className={`md:h-96  h-64 w-full hover:shadow-md  `}
						key={index}
					>
						<BackgroundImage
							preserveStackingContext
							{...getBgImageFromService(service)}
							className="  h-full w-full  "
						>
							<div className={`flex py-10 px-10 items-end h-full ${overlay}`}>
								<h3 className="text-white md:text-2xl lg:text-4xl">{service.name}</h3>
							</div>
						</BackgroundImage>
					</Link>
				))}
			</div>
		</div>
	)
}
