import React, { ReactNode } from 'react'
import { RiArrowRightSLine } from 'react-icons/ri'
import { CircleButton } from '../../../Shared/Button/CircleButton'
import { Link } from 'gatsby'

export interface LeftSidePageLinkProps {
	title: string
	subtitle: string
	text?: string
	link?: string
}

export const LeftSidePageLink = (props: LeftSidePageLinkProps) => {
	const { title, subtitle, text, link } = props
	return (
		<div className="w-1/2 bg-white rounded-tl-lg rounded-br-lg md:p-8 p-4 shadow-md relative">
			<p className="text-lg font-semibold">{title}</p>
			<p className="mt-2.5 text-sm md:text-base">{subtitle}</p>
			<p className="h-10 font-primary">{text && <span className="text-primary font-bold">{text}</span>}</p>

			<div className="-bottom-4 md:-bottom-7 absolute">
				<Link to={link}>
					<CircleButton>
						<RiArrowRightSLine className="text-3xl" />
					</CircleButton>
				</Link>
			</div>
		</div>
	)
}
