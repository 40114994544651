import React, { ReactNode } from 'react'
import { Button } from '../../../Shared/Button/Button'
import { LeftSidePageLinks } from './LeftSidePageLinks'

export interface LeftSideProps {
	className?: string
	children?: ReactNode
}
export const LeftSide = (props: LeftSideProps) => {
	const { className = '', children } = props
	return (
		<div className={props.className}>
			<h1 className="text-indigo ">Súkromná&nbsp;očná ambulancia v&nbsp;Banskej&nbsp;Bystrici</h1>

			<a href="/kontakt" target="_blank" rel="noopener noreferrer" className="mt-14  inline-flex">
				<Button>Kontaktovať</Button>
			</a>
			<div className="">
				<LeftSidePageLinks className="md:flex hidden md:space-x-6 md:space-y-0" />
			</div>
		</div>
	)
}
