import React from 'react'
import { Container } from '../../Shared/Container/Container'
import { DoctorCard } from './components/DoctorCard'
import { getAllDoctors } from './hooks/getAllDoctors'

export interface DoctorsSectionProps {
	className?: string
}

export const DoctorsSection = (props: DoctorsSectionProps) => {
	const doctors = getAllDoctors()

	return (
		<div className="md:py-20 py-10">
			<Container>
				<h2 className=" text-center">Lekári</h2>
				<div className="flex justify-center md:space-x-6  md:flex-row flex-col space-x-0">
					{doctors.nodes.map((doctor, index: number) => (
						<DoctorCard
							key={index}
							name={doctor.name}
							position={doctor.position}
							description={doctor.description}
							image={doctor.image}
						/>
					))}
				</div>
				<div>
					<div className={` flex justify-center flex-col items-center mt-16`}>
						<h3 className="mt-4 text-2xl">Bc. Ľubica Datková</h3>
						<p className="text-primary text-base sm:text-xl mt-2">Sestra na ambulancii</p>
					</div>
				</div>
			</Container>
		</div>
	)
}
