import React, { ReactNode } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export interface RightSideProps {
	className?: string
}

export const RightSide = (props: LeftSideRightSidePropsProps) => {
	const { className = '' } = props
	return (
		<div className={className}>
			<div className="">
				<StaticImage
					src="../../../../assets/images/hero.png"
					alt="Fotka oka, očna ambulancia"
					placeholder="blurred"
					layout="constrained"
					width={550}
				/>
			</div>
		</div>
	)
}
