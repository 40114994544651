import React from 'react'

export interface TextProps {
	className?: string
}

export const Text = (props: TextProps) => {
	return (
		<div className={`${props.className}`}>
			<div className="flex">
				<div>
					<h3 className="font-primary font-normal">Sme tu pre vás</h3>
					<p className="text-terciary mt-4 md:pr-24  sm:pr-5 pr-0">
						Očná ambulancia v centre Banskej Bystrice ponúka komplexnú zdravotnú starostlivosť,
						ľudský prístup, príjemné prostredie a minimálny čas strávený čakaním na vyšetrenie vďaka
						bezplatnému objednávaniu na čas.
					</p>
				</div>
			</div>
		</div>
	)
}
