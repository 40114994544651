import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export interface ImagesProps {
	className?: string
}

export const Images = (props: ImagesProps) => {
	return (
		<div className={`${props.className}`}>
			<div className="z-10 relative  sm:left-0  pt-4 sm:pt-0 ">
				<StaticImage
					src="../../../../assets/images/forward-image.jpg"
					alt="Súkromná očná ambulancia v Banskej Bystrici"
					placeholder="blurred"
					layout="constrained"
					width={400}
					className=""
				/>
			</div>
			<div className="absolute   top-24 z-0 hidden md:block md:-right-32 lg:right-8   ">
				<StaticImage
					src="../../../../assets/images/ocna.jpg"
					alt="Súkromná očná ambulancia v Banskej Bystrici"
					placeholder="blurred"
					layout="constrained"
					width={320}
				/>
			</div>
		</div>
	)
}
