import React from 'react'
import { CONTACT } from '../../../../lib/consts'
import { routes } from '../../../../lib/routes'
import { LeftSidePageLink } from './LefSidePageLink'

export interface LeftSidePageLinksProps {
	className?: string
}

export const LeftSidePageLinks = (props: LeftSidePageLinksProps) => {
	const { className = '' } = props
	return (
		<div className={`mt-20 justify-between ${className}`}>
			<LeftSidePageLink link={routes.contact.to} title="Naša adresa" subtitle={CONTACT.address} />
			<LeftSidePageLink
				link={`${routes.about.to}#ordinacne-hodiny`}
				title="Ordinačné hodiny"
				subtitle={CONTACT.workDays}
				text={CONTACT.worktime}
			/>
		</div>
	)
}
