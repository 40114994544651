import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export interface DoctorCardProps {
	className?: string
	image?: IGatsbyImageData
	name: string
	position: string
	description: string
}

export const DoctorCard = (props: DoctorCardProps) => {
	const { image, name, position, description, className } = props
	const doctorImage = getImage(image)

	return (
		<div className={`${className} flex justify-center flex-col items-center mt-10`}>
			<div className="block sm:w-96 sm:h-96 w-full">
				<GatsbyImage
					image={doctorImage}
					alt={name}
					placeholder="blurred"
					imgClassName="object-center"
					className="rounded-tl-3xl rounded-br-3xl object-center"
				/>
			</div>
			<h3 className="mt-4 text-2xl">{name}</h3>
			<p className="text-primary text-base sm:text-xl mt-2">{position}</p>
		</div>
	)
}
