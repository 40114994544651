import React, { ReactNode } from 'react'

export interface CircleButtonProps {
	className?: string
	children?: ReactNode
}

export const CircleButton = (props: CircleButtonProps) => {
	const { className = '', children } = props
	return (
		<div
			className={`text-white bg-primary inline-flex py-3 px-3 rounded-full border-primary hover:text-primary 
			hover:bg-white hover:border-primary  border-2 hover:border-2 duration-500 easy-out	 ${className}`}
		>
			{children}
		</div>
	)
}
