import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import { Container } from '../../Shared/Container/Container'
import { MakeAnAppointmentHeader } from './components/MakeAnAppointmentHeader'
import { graphql, StaticQuery } from 'gatsby'
import { MakeAnAppointmentFooter } from './components/MakeAnAppointmentFooter'

export interface MakeAnAppointmentSectionProps {
	className?: string
}

export const MakeAnAppointmentSection = ({}) => (
	<StaticQuery
		query={graphql`
			query {
				desktop: file(relativePath: { eq: "images/bg-eyes.png" }) {
					childImageSharp {
						fluid(quality: 90, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`}
		render={(data: any) => {
			const imageData = data.desktop.childImageSharp.fluid
			return (
				<section className="md:py-20 py-10">
					<BackgroundImage
						Tag="section"
						className=""
						fluid={imageData}
						backgroundColor={`#040e18`}
						background-size="cover"
					>
						<Container className={`py-16 `}>
							<div className="">
								<MakeAnAppointmentHeader />
							</div>
						</Container>
						{/* <MakeAnAppointmentFooter /> */}
					</BackgroundImage>
				</section>
			)
		}}
	/>
)
