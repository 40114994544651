import React, { ReactNode } from 'react'
import { Container } from '../../Shared/Container/Container'
import { LeftSide } from './components/LeftSide'
import { LeftSidePageLinks } from './components/LeftSidePageLinks'
import { RightSide } from './components/RightSide'
import { hero } from './hero.module.scss'

export interface HeroProps {
	className?: string
	children?: ReactNode
}

export const Hero = (props: HeroProps) => {
	const { className = '', children } = props
	return (
		<section className={`md:pt-40 sm:pt-20 pt-8 md:pb-32  pb-7 ${hero}`}>
			<Container className="">
				<div className="flex lg:space-x-20 sm:space-x-2 md:space-x-6 justify-between items-center sm:flex-row flex-col">
					<LeftSide className="sm:w-1/2 w-full order-1 sm:order-1 pt-10 sm:pt-0" />

					<RightSide className="sm:w-1/2 w-full order-2 sm:order-2 flex justify-center items-center pt-20 md:pt-0" />
				</div>
				<div className="">
					<LeftSidePageLinks className="flex md:hidden flex-row space-x-6" />
				</div>
			</Container>
		</section>
	)
}
