import React from 'react'
import { Container } from '../../Shared/Container/Container'
import { ServicesHome } from './components/ServicesHome'

export interface ServicesSectionProps {
	className?: string
}
export const ServicesSection = (props: ServicesSectionProps) => {
	return (
		<section className={`${props.className} md:py-20 py-10 overflow-hidden`}>
			<Container>
				<ServicesHome />
			</Container>
		</section>
	)
}
