import React from 'react'
import { DoctorsSection } from '../components/Home/DoctorsSection/DoctorsSection'
import { Hero } from '../components/Home/Hero/Hero'
import { MakeAnAppointmentSection } from '../components/Home/MakeAnAppointmentSection/MakeAnAppointmentSection'
import { ProcedureSection } from '../components/Home/ProcedureSection/ProcedureSection'
import { ProfessionClinicSection } from '../components/Home/ProfessionClinicSection/ProfessionClinicSection'
import { ReferencesSection } from '../components/Home/ReferencesSection/ReferencesSection'
import { ServicesSection } from '../components/Home/ServicesSection/ServicesSection'
import { Container } from '../components/Shared/Container/Container'
import Layout from '../components/Shared/Layout/Layout'
import { Representation } from '../components/Shared/Representation/Representation'
import { SEO } from '../components/Shared/SEO/SEO'

const IndexPage = () => (
	<Layout>
		<SEO description="Očná ambulancia v centre Banskej Bystrice ponúka komplexnú zdravotnú starostlivosť, ľudský prístup, príjemné prostredie a minimálny čas strávený čakaním na vyšetrenie vďaka bezplatnému objednávaniu na čas." />
		<Hero />
		{/* <Representation className="mt-24 md:mt-0" /> */}
		<ProfessionClinicSection />
		<MakeAnAppointmentSection />
		<DoctorsSection />
		<ServicesSection />
		<ReferencesSection />
		{/* <ProcedureSection /> */}
	</Layout>
)

export default IndexPage
