import React from 'react'
import { Container } from '../../Shared/Container/Container'
import { Images } from './components/Images'
import { Text } from './components/Text'

export interface ProfessionClinicSectionProps {
	className?: string
}

export const ProfessionClinicSection = (props: ProfessionClinicSectionProps) => {
	return (
		<section className="py-20 overflow-hidden">
			<Container>
				<div className="text-center">
					<h2>O nás</h2>
				</div>
				<div className="flex sm:space-x-2 items-center justify-between mt-6 flex-col sm:flex-row">
					<Text className="sm:w-1/2 w-full" />
					<Images className="relative sm:w-1/2 w-full" />
				</div>
			</Container>
		</section>
	)
}
