import { graphql, useStaticQuery } from 'gatsby'
export const getAllDoctors = () => {
	const { allStrapiDoctors } = useStaticQuery(graphql`
		query allDoctors {
			allStrapiDoctors(limit: 10) {
				nodes {
					name
					position
					image {
						childImageSharp {
							gatsbyImageData(
								aspectRatio: 1
								placeholder: BLURRED
								layout: FULL_WIDTH
								transformOptions: { cropFocus: CENTER }
							)
						}
					}
				}
			}
		}
	`)
	return allStrapiDoctors
}
